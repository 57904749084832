import { CalendarSlot } from "../../dto/model";
import { CalendarSlotItem } from "./calendar-slot";
import { strings } from "../../lang";
import { Empty } from "antd";

import "./sales-call-calendar.css";

export interface CalendarBodyProps {
  slots: CalendarSlot[];
  isLoading: boolean;
  onBookSlot: (slot: CalendarSlot) => {};
}

export const CalendarBody = ({
  slots,
  isLoading,
  onBookSlot,
}: CalendarBodyProps) => {
  if (slots.length < 1) {
    return (
      <Empty
        className="calendar-body__empty"
        description={strings.noSlotsAvailable}
      />
    );
  }

  return (
    <div className="calendar-body">
      {slots.map((s) => (
        <CalendarSlotItem
          key={s.startDate.toString()}
          slot={s}
          onBookSlot={onBookSlot}
          isLoading={isLoading}
        ></CalendarSlotItem>
      ))}
    </div>
  );
};
