import { CalendarSlot, Result, Slot } from "../../dto/model";
import { strings } from "../../lang";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

type BookedResponseTypeProps = {
  slotToBook: CalendarSlot;
  bookedSlot: Result<Slot> | undefined;
};

// This function tries to parse the possible errors returned by the api,
// which can come in a json string with the following format: { message: string }
const parseErrors = (errors: string[] | null) => {
  if (!errors) {
    return strings.errorOccurred;
  }

  try {
    const errorMsg = errors[0];

    // If the error code is a localized string, try to get it from localization.
    // (e.g.: NO_SLOTS_AVAILABLE)
    if (strings.getString(errorMsg)) {
      return strings.getString(errorMsg);
    }

    // Else it might be a json string...
    const msgs = JSON.parse(errors[0]);
    if (msgs.message) {
      return msgs.message;
    }

    // Else just return whatever it came in here.
    return errors[0];
  } catch (e) {
    return errors;
  }
};

const BookResponse = ({ slotToBook, bookedSlot }: BookedResponseTypeProps) => {
  return (
    <div>
      {!bookedSlot && (
        <>
          <p>{strings.followingSlotWillBeBooked}:</p>
          <ul>
            <li>
              {strings.date}:{" "}
              <b>{slotToBook.startDate.format("ddd, DD.MM.YYYY")}</b>
            </li>
            <li>
              {strings.startTime}: <b>{slotToBook.startDate.format("HH:mm")}</b>
            </li>
          </ul>
          <p>{strings.wouldYouLikeToProceed}</p>
        </>
      )}

      {bookedSlot && bookedSlot.isSuccess && (
        <>
          <h4>
            {" "}
            <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
            {strings.bookingSuccessful}
          </h4>
          <p>
            {strings.salesManager}: <b>{bookedSlot.data.salesManagerName}</b>
          </p>
          <ul>
            <li>
              {strings.date}:{" "}
              <b>{slotToBook.startDate.format("ddd, DD.MM.YYYY")}</b>
            </li>
            <li>
              {strings.startTime}: <b>{slotToBook.startDate.format("HH:mm")}</b>
            </li>
          </ul>
          <p>{strings.youCanCloseThisWindow}</p>
          {/*<TextArea defaultValue={bookedSlot.data.metaBookingInfo}></TextArea>*/}
        </>
      )}

      {bookedSlot && !bookedSlot.isSuccess && (
        <>
          <p>
            {" "}
            <CloseCircleTwoTone twoToneColor="#eb2f96" />{" "}
            {strings.errorOccurred}
          </p>
          <p>{parseErrors(bookedSlot.errors)}</p>
          {!(
            bookedSlot.errors?.includes("NO_SLOTS_AVAILABLE") ||
            bookedSlot.errors?.includes("No Sales managers available")
          ) && <p>{strings.customerCannotBeBooked}</p>}
        </>
      )}
    </div>
  );
};

export default BookResponse;
