import dayjs from "dayjs";
import { Button, Col, Row, Select, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { UserOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./sales-call-calendar.css";
import { Lead } from "../../dto/model";
import { strings } from "../../lang";
import { useSearchParams } from "react-router-dom";

export interface CalendarParametersChanged {
  calendarWeek: number;
  calendarYear: number;
  language: string | undefined;
}

export interface CalendarParameterProps {
  onRefresh: (val: CalendarParametersChanged) => void;
  displayUserInfo: boolean;
  lead: Lead;
  isLoading: boolean;
}

export const CalendarParameters = ({
  onRefresh,
  lead,
  displayUserInfo,
  isLoading,
}: CalendarParameterProps) => {
  let currentDay = dayjs();

  const [searchParams] = useSearchParams();

  const weeks = [0, 1, 2, 3, 4].map((i) => {
    const currentWeek = currentDay.isoWeek();
    const currentYear = currentDay.year();
    currentDay = currentDay.add(7, "day");
    return `${currentYear}-${currentWeek.toString().padStart(2, "0")}`;
  });

  const [currentCW, setCurrentCW] = useState(weeks[0]);
  const [currentLanguage, setCurrentLanguage] = useState<string>();

  const onWeekChanged = (selectedCW: string) => {
    const cw = parseInt(selectedCW.split("-")[1], 10);
    const cy = parseInt(selectedCW.split("-")[0], 10);
    setCurrentCW(selectedCW);
    onRefresh({
      calendarWeek: cw,
      calendarYear: cy,
      language: currentLanguage,
    });
  };

  const onNextWeekClick = () => {
    const idx = weeks.indexOf(currentCW);
    if (idx < weeks.length - 1) {
      onWeekChanged(weeks[idx + 1]);
    }
  };

  const onPreviousWeekClick = () => {
    const idx = weeks.indexOf(currentCW);
    if (idx > 0) {
      onWeekChanged(weeks[idx - 1]);
    }
  };


  const toolTip = (
    <div>
      <p>Tier: {lead.tier}</p>
      <p>
        {strings.products}: {lead.products}
      </p>
      {lead.referredByName && (
        <p>
          {strings.referredBy}: {lead.referredByName}
        </p>
      )}
    </div>
  );

  return (
    <Content className="cal-params">
      <Row gutter={10}>
        <Col span={6} className="cal-params__col">
          <Select
            style={{ marginTop: 15 }}
            defaultValue={currentCW}
            value={currentCW}
            disabled={isLoading}
            options={weeks.map((x) => ({
              label: `CW: ${x}`,
              value: x,
            }))}
            onChange={onWeekChanged}
          ></Select>
        </Col>
        <Col span={3} className="cal-params__col cal-params__arrows">
          <div style={{ marginTop: 15 }}>
            <Button
              type="text"
              size="small"
              onClick={onPreviousWeekClick}
              disabled={currentCW === weeks[0] || isLoading}
            >
              <LeftOutlined />
            </Button>
            <Button
              type="text"
              size="small"
              onClick={onNextWeekClick}
              disabled={currentCW === weeks[weeks.length - 1] || isLoading}
            >
              <RightOutlined />
            </Button>
          </div>
        </Col>
        <Col span={6} className="cal-params__col cal-params__language">
        </Col>
        <Col span={9} className="cal-week__user-col">
          {displayUserInfo && (
            <Tooltip placement="right" title={toolTip}>
              <UserOutlined className="cal-week__icon" /> {lead.firstName}{" "}
              {lead.lastName}
            </Tooltip>
          )}
        </Col>
      </Row>
    </Content>
  );
};
