import {
  TotalOpportunities,
  OpportunityComplete,
  CaseInfo,
  Result,
  OpportunitySearchResult,
  OpportunityAssignDto,
} from '../dto/model';

const rootUrl = (process as any).env.REACT_APP_API_ROOT_URL;

const handleResponse = async (
  url: string,
  options: RequestInit
): Promise<Response> => {
  const response = await fetch(url, options);

  if (!response.ok) {
    throw {
      status: response.status,
      statusText: response.statusText,
    };
  }

  return response;
};

export const bookingPageService = {
  async cancelAppointment(
    externalAppointmentId: string,
    opportunityId: string,
    accessToken: string,
    isRescheudling: boolean
  ): Promise<Result<any>> {
    const url = `${rootUrl}/api/v1/slots/${externalAppointmentId}/cancel?opportunityId=${opportunityId}&isRescheduling=${isRescheudling}`;
    const response = await handleResponse(url, {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    const result = await response.json();
    return result;
  },

  async searchForOpportunities(
    accessToken: string,
    query: string
  ): Promise<OpportunitySearchResult[]> {
    const url = `${rootUrl}/api/v1/search?${query}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });
      const result = await response.json();
      return result;
    } catch (e: any) {
      return [];
    }
  },

  async assignInboundOpportunity(
    body: OpportunityAssignDto,
    accessToken: string
  ): Promise<Result<any>> {
    const url = `${rootUrl}/api/v1/inbound-center/assign-case`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }),
        body: JSON.stringify(body),
      });
      const result = await response.json();
      return result;
    } catch (e: any) {
      return {
        isSuccess: false,
        errors: [e?.statusText],
        data: {},
      };
    }
  },

  async getOrAssignPriorityCase(
    accessToken: string,
    salesChannel: string
  ): Promise<Result<CaseInfo>> {
    let url = `${rootUrl}/api/v1/case?salesChannel=${salesChannel}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });
      const result = await response.json();
      return result;
    } catch (e: any) {
      return {
        data: {} as CaseInfo,
        isSuccess: false,
        errors: [e?.statusText],
      };
    }
  },

  async getAssignedCase(
    accessToken: string,
    salesChannel: string
  ): Promise<Result<CaseInfo>> {
    let url = `${rootUrl}/api/v1/assigned-case?salesChannel=${salesChannel}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        return result;
      }

      return {
        data: {} as CaseInfo,
        isSuccess: false,
        errors: [response.statusText],
      };
    } catch (e: any) {
      return {
        data: {} as CaseInfo,
        isSuccess: false,
        errors: [e?.statusText],
      };
    }
  },

  async closeCase(
    caseComplete: OpportunityComplete,
    salesChannel: string,
    accessToken: string
  ): Promise<Result<CaseInfo>> {
    let url = `${rootUrl}/api/v1/case?salesChannel=${salesChannel}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(caseComplete),
    });

    //TODO: Handle cases where the request is not success
    const result = await response.json();
    return result;
  },

  async releaseCase(
    caseId: string,
    opportunityId: string,
    salesChannel: string,
    accessToken: string
  ): Promise<void> {
    let url = `${rootUrl}/api/v1/case/release?caseId=${caseId}&opportunityId=${opportunityId}&salesChannel=${salesChannel}`;
    const response = await handleResponse(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });
  },

  async addCaseComment(
    caseId: string,
    opportunityId: string,
    comment: string,
    salesChannel: string,
    accessToken: string
  ): Promise<void> {
    let url = `${rootUrl}/api/v1/case/comment?salesChannel=${salesChannel}`;
    const body = {
      caseId,
      opportunityId,
      comment,
    };
    const response = await handleResponse(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body),
    });
    const result = await response.json();
  },

  async updateCaseAssignment(
    caseId: string,
    salesChannel: string,
    accessToken: string
  ): Promise<Result<Date>> {
    let url = `${rootUrl}/api/v1/case-assignment?caseId=${caseId}&salesChannel=${salesChannel}`;
    const response = await handleResponse(url, {
      method: 'PATCH',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
    });
    const result = await response.json();
    return result;
  },

  async getAllCases(
    accessToken: string,
    shouldLimit: boolean
  ): Promise<TotalOpportunities> {
    const url = `${rootUrl}/api/v1/cases?shouldLimit=${shouldLimit}`;
    const response = await handleResponse(url, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
    });
    const result = await response.json();
    return result;
  },

  async BulkUploadOpportunities(
    req: any,
    salesChannel: string,
    accessToken: string
  ) {
    const url = `${rootUrl}/api/v1/cases/bulk-upload?salesChannel=${salesChannel}`;
    const response = await handleResponse(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(req),
    });
    return await response.text();
  },
};
