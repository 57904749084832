import './online-booking-page.css';
import { AudioOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { strings } from '../../../lang';
import { CaseInfo } from '../../../dto/model';

type OnlineRecordingBookingPageProps = {
  caseInfo: CaseInfo | null;
  isAgentOnline: boolean;
  isUserRecorded: boolean;
};

export const OnlineRecordingBookingPage = ({ caseInfo, isAgentOnline, isUserRecorded }: OnlineRecordingBookingPageProps) => {
  const [isRecording, setRecording] = useState<boolean>(false);

  useEffect(() => {
    if (isUserRecorded && isAgentOnline) {
      setRecording(true);
    } else {
      setRecording(false);
    }
  }, [isAgentOnline]);

  useEffect(() => {}, [caseInfo?.caseId]);

  return (
    <div className={`bp-section-recording ${isRecording && isUserRecorded && isAgentOnline ? '' : 'bp-section-recording-hidden'}`}>
      <AudioOutlined className={`bp-microphone-${isRecording && isUserRecorded && isAgentOnline ? 'green' : 'black'}`} />
      <span className="bp-recording-text">{isRecording && isUserRecorded && isAgentOnline ? strings.recordingInProgress : ''}</span>
    </div>
  );
};
