import './customer-lost-modal.css';
import { Modal, Select } from 'antd';
import { strings } from '../../../lang';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { SalesChannel } from '../../../dto/model';

export interface CustomerLostInfo {
  reasons: string[];
  description: string;
}

export interface CustomerLostBodyProps {
  open: boolean;
  onOk: (customerLostInfo: CustomerLostInfo) => Promise<void>;
  onCancel: () => void;
  inboundCenter?: boolean | undefined;
  salesChannel: SalesChannel;
}

export const CustomerLostModal = ({ open, onOk, onCancel, inboundCenter, salesChannel }: CustomerLostBodyProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const defaultErrorState = {
    tooManySelected: false,
    noSelected: false,
    noInput: false,
    tooMuchInput: false,
  };
  const [error, setError] = useState(defaultErrorState);

  const onOkPressed = async () => {
    if (selectedOptions?.length > 3) {
      setError({ ...defaultErrorState, tooManySelected: true });
      return;
    }
    if (selectedOptions?.length < 1) {
      setError({ ...defaultErrorState, noSelected: true });
      return;
    }

    if (description?.trim()?.length > 255) {
      setError({ ...defaultErrorState, tooMuchInput: true });
      return;
    }

    try {
      setIsLoading(true);
      await onOk({
        reasons: selectedOptions,
        description,
      });
      cleanState();
    } catch (e) {
      alert(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelPressed = () => {
    cleanState();
    onCancel();
  };

  const cleanState = () => {
    setSelectedOptions([]);
    setDescription('');
    setError({
      tooManySelected: false,
      noSelected: false,
      noInput: false,
      tooMuchInput: false,
    });
  };

  return (
    <Modal
      title={salesChannel === SalesChannel.Revision ? strings.looseCustomerButton : strings.modalTitleCustomerLost}
      centered
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      keyboard={false}
      open={open}
      onOk={() => onOkPressed()}
      okText={inboundCenter ? strings.backToInboundCenter : strings.saveAndOpenNextCase}
      cancelText={strings.cancel}
      onCancel={() => onCancelPressed()}
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <b>{salesChannel === SalesChannel.Revision ? strings.modalTextCustomerLostRevision : strings.modalTextCustomerLost}</b>
      <br />
      {error.noSelected && <div style={{ color: 'red' }}>{strings.customerLostAtLeastOneOptionError}</div>}
      {error.tooManySelected && <div style={{ color: 'red' }}>{strings.customerLostUpTo3OptionsError}</div>}
      <Select
        className="cust-lost-select"
        mode="multiple"
        placeholder={strings.selectAtLeastOneReason}
        options={strings.customerLostOptions}
        onChange={setSelectedOptions}
        maxTagCount={3}
        value={selectedOptions}
        status={error.noSelected === true || error.tooManySelected ? 'error' : ''}
      />
      {error.noInput && <div style={{ color: 'red' }}>{strings.customerLostNoCharactersError}</div>}
      {error.tooMuchInput && <div style={{ color: 'red' }}>{strings.customerLostTooManyCharactersError}</div>}
      <TextArea
        maxLength={255}
        onChange={(e: any) => setDescription(e.target.value)}
        rows={3}
        value={description}
        placeholder={strings.customerLostPlaceholder}
        status={error.noInput || error.tooMuchInput === true ? 'error' : ''}
      />
    </Modal>
  );
};
