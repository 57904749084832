import { SalesChannel } from "./model";

export enum BookingConfigurationStatus {
  FirstTimeScheduling = "FirstTimeScheduling",
  ReschedulingNeeded = "ReschedulingNeeded"
}

export interface SnoozeRange {
  startCount: number;
  endCount: number;
  snoozeDuration: number;
  timeFrame: string;
}

export interface BookingConfiguration {
  id?: number;
  feId?: string;
  createdDate: string; // Use string to represent DateTime
  lastModifiedDate: string; // Use string to represent DateTime
  createdBy: string;
  salesChannel: SalesChannel; // Assuming SalesChannel is defined elsewhere
  bookingStatus: BookingConfigurationStatus;
  abTestingFragment: string;
  snoozeRanges: SnoozeRange[];
  isDeleted?: boolean;
}

export enum TimeFrames {
  m = "Minutes",
  H = "Hours",
  D = "Days",
  W = "Weeks",
  M = "Months"
}

export const salesChannelOptions = Object.keys(SalesChannel).map(key => ({
  label: `${SalesChannel[key as keyof typeof SalesChannel]}`,
  value: SalesChannel[key as keyof typeof SalesChannel]
}));

export const bookingStatusOptions = Object.keys(BookingConfigurationStatus).map(key => ({
  label: `${BookingConfigurationStatus[key as keyof typeof BookingConfigurationStatus]}`,
  value: BookingConfigurationStatus[key as keyof typeof BookingConfigurationStatus]
}));

export const timeFrameOptions = Object.keys(TimeFrames).map(key => ({
  label: `${TimeFrames[key as keyof typeof TimeFrames].toString()}`,
  value: key
}));