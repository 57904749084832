import { Input, Modal, Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';
import { strings } from '../../../lang';
import TextArea from 'antd/es/input/TextArea';

export interface WrongPhoneNumberModalProps {
  open: boolean;
  onOk: (message: string) => void;
  showModal: (val: boolean) => void;
}

const WrongPhoneNumberModal: React.FC<WrongPhoneNumberModalProps> = ({ open, onOk, showModal }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [showTextInput, setShowTextInput] = useState<boolean>(false);
  const [freeText, setFreeText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await onOk(!freeText ? `Wrong Phone Number: ${selectedOption!}` : `Wrong Phone Number: ${selectedOption}${freeText!}`);
    } finally {
      setIsLoading(false);
      setFreeText(null);
      setShowTextInput(false);
      setSelectedOption(null);
    }
  };

  const handleCancel = () => {
    showModal(false);
    setSelectedOption(null);
    setShowTextInput(false);
    setFreeText(null);
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    setShowTextInput(e.target.id === '4');
    setSelectedOption(e.target.value);
  };

  const onTextFieldChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFreeText(e.target.value);
  };

  return (
    <Modal
      title={strings.wrongPhoneNumber.title}
      centered
      closable={false}
      destroyOnClose={true}
      keyboard={false}
      maskClosable={false}
      open={open}
      onOk={handleSubmit}
      okButtonProps={{
        title: strings.wrongPhoneNumber.buttonClarification,
        loading: isLoading,
        disabled: (!selectedOption && !showTextInput) || (showTextInput && !freeText),
      }}
      okText={strings.wrongPhoneNumber.saveAndOpenNextCase}
      onCancel={handleCancel}
      cancelButtonProps={{ disabled: isLoading }}
      cancelText={strings.wrongPhoneNumber.cancel}
    >
      <p>* {strings.wrongPhoneNumber.prompt}</p>
      <Radio.Group onChange={onRadioChange}>
        <Radio id="1" value="Number looks invalid (e.g. 000000000 or 123456789)" style={{ marginBottom: '5px' }}>
          {strings.wrongPhoneNumber.reasons[0]}
        </Radio>
        <Radio id="2" value="No connection under this number" style={{ marginBottom: '5px' }}>
          {strings.wrongPhoneNumber.reasons[1]}
        </Radio>
        <Radio id="3" value="Wrong Person (e.g. did not know about Enpal)" style={{ marginBottom: '5px' }}>
          {strings.wrongPhoneNumber.reasons[2]}
        </Radio>
        <Radio id="4" value="Other: " style={{ marginBottom: '5px' }}>
          {strings.wrongPhoneNumber.reasons[3]}
        </Radio>
      </Radio.Group>
      {showTextInput && <TextArea placeholder={strings.wrongPhoneNumber.pleaseSpecifyReason} onChange={onTextFieldChanged} />}
    </Modal>
  );
};

export default WrongPhoneNumberModal;
