import './online-booking-page-case-comments.css';
import { strings } from '../../../lang';
import { CaseComments, SalesChannel } from '../../../dto/model';
import { List, Modal } from 'antd';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useSalesChannelState } from '../../../store/header-state';

type CaseCommentSectionProps = {
  caseComments: CaseComments[];
  inputCaseComment: string;
  onInputCaseCommentChange: (inputCaseComment: string) => void;
  shouldShowPreviousComments: boolean;
};

const CaseCommentSection = ({ caseComments, inputCaseComment, onInputCaseCommentChange, shouldShowPreviousComments }: CaseCommentSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rows, setRows] = useState(3);
  const { salesChannel } = useSalesChannelState();
  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    setRows(inputCaseComment.length > 0 ? 12 : 3);
  }, [inputCaseComment]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addTemplateToComments = () => {
    const template = `Gesprochen mit:
Dachbelag:
Eigentümer:
Wohnsitz:
PV vorhanden:
Denkmal geschützt:
Gewerblich genutzt:
Stromverbrauch:
Monatlicher Abschlag:
60 Minuten Teams:
Solarexperten für DC angekündigt:
Sonstige persönliche Infos:`;
    onInputCaseCommentChange(inputCaseComment + template);
    setRows(12);
  };

  return (
    <>
      <section className="bp-section bp-comment" style={{ flexDirection: 'column' }}>
        {salesChannel != SalesChannel.FieldSales && <span
          style={{ marginBottom: '10px' }}
        >
          Textbausteine:
          <a
            style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: '10px' }}
            onClick={addTemplateToComments}
          >
            Booking Kommentar-Template
          </a>
        </span>}
        <TextArea value={inputCaseComment} onChange={(e) => onInputCaseCommentChange(e.target.value)} rows={rows} placeholder={strings.opportunityCommentsPlaceholder} />
        <div
          style={{
            marginTop: '10px',
            textAlign: 'center',
            textDecoration: 'underline',
            color: 'rgba(0, 0, 0, 0.5)',
            cursor: 'pointer',
          }}
          onClick={showModal}
        >
          {shouldShowPreviousComments && strings.caseCommentsBtn(caseComments.length)}
        </div>
      </section>
      <Modal title={strings.caseCommentsModalTitle} open={isModalVisible} onCancel={handleCancel} footer={null}>
        <List
          itemLayout="horizontal"
          dataSource={caseComments}
          renderItem={(comment) => (
            <List.Item>
              <List.Item.Meta title={comment.createdBy} description={<div className="description-text">{comment.body}</div>} />
              <div>{dayjs(comment.createdOn).format('DD MMM YYYY, HH:mm')}</div>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default CaseCommentSection;
