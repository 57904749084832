import { Button } from "antd";
import { CalendarSlot, SalesChannel } from "../../dto/model";

import "./sales-call-calendar.css";

export interface CalendarSlotProps {
  slot: CalendarSlot;
  isLoading: boolean;
  onBookSlot: (slot: CalendarSlot) => {};
}

export const CalendarSlotItem = ({
  slot,
  isLoading,
  onBookSlot,
}: CalendarSlotProps) => {
  let style: any;
    style = { gridColumn: slot.startDate.isoWeekday() };

  return (
    <Button
      style={style}
      className={ `calendar-slot ${slot.salesChannel === SalesChannel.FieldSales ? "calendar-slot--field-sales" : ""}` }
      onClick={(_) => {
        if (isLoading) return;
        onBookSlot(slot);
      }}
    >
      {slot.startDate.format("HH:mm")}
      <div className="calendar-slot__count">{slot.dailyAvailableCount}</div>
    </Button>
  );
};
