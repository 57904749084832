import { BookingConfiguration } from '../dto/admin-config-models';
import { Result, SalesChannel } from '../dto/model';

const rootUrl = (process as any).env.REACT_APP_API_ROOT_URL;

export async function getConfigurations(
  accessToken: string,
  salesChannel: SalesChannel
): Promise<BookingConfiguration[] | null> {
  try {
    const response = await fetch(
      `${rootUrl}/api/v1/admin/configurations?salesChannel=${salesChannel}`,
      {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
    const data = await response.json();
    return data; // Add this line to return the data
  } catch (error) {
    alert(`Failed to get configuration. Exception while loading ${error}`);
  }
  return null;
}

export async function postConfigurations(
  accessToken: string,
  payload: BookingConfiguration[]
): Promise<Result<number>> {
  try {
    const response = await fetch(`${rootUrl}/api/v1/admin/configurations`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return {
      data: 0,
      errors: ['Failed to save configuration'],
      isSuccess: false,
    };
  }
}
