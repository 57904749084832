import { DatabaseOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space } from 'antd';
import { strings } from '../../../lang';
import { useSalesChannelState } from '../../../store/header-state';
import { SalesChannel } from '../../../dto/model';
import { getSalesChannelLabel } from '../../../utils/inbound-utils';

const items: MenuProps['items'] = [
  {
    label: getSalesChannelLabel(SalesChannel.DigitalSales),
    key: SalesChannel.DigitalSales,
    icon: <DatabaseOutlined />,
  },
  {
    label: getSalesChannelLabel(SalesChannel.CrmSales),
    key: SalesChannel.CrmSales,
    icon: <DatabaseOutlined />,
  },
  {
    label: getSalesChannelLabel(SalesChannel.FieldSales),
    key: SalesChannel.FieldSales,
    icon: <DatabaseOutlined />,
  },
  {
    label: getSalesChannelLabel(SalesChannel.Revision),
    key: SalesChannel.Revision,
    icon: <DatabaseOutlined />,
  },
];

const SalesChannelDropdown = () => {
  const [, salesChannelLabel, setSalesChannel] = useSalesChannelState(
    (state) => [
      state.salesChannel,
      state.salesChannelLabel,
      state.setSalesChannel,
    ]
  );

  const handleMenuClick = (e: any) => {
    const selected = items.find((item) => item?.key === e.key);
    if (!selected) {
      return;
    }
    setSalesChannel(selected.key! as SalesChannel);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <p>{strings.salesChannelDropdownLabel}</p>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            <DatabaseOutlined />
            {salesChannelLabel}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default SalesChannelDropdown;
