import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import './sales-call-calendar.css';
import {
  Modal,
  Skeleton,
  Result as AntdResult,
  message,
  notification,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CalendarSlot, Lead, Result, Slot } from '../../dto/model';
import { salesAppointmentsService } from '../../services/sales-appointments-service';
import { CalendarHeader } from './calendar-header';
import { CalendarBody } from './calendar-body';
import { CalendarParameters } from './calendar-parameters';
import { useMsal } from '@azure/msal-react';
import { GetAccessToken } from '../../utils/auth-utils';
import { scopes } from '../../authConfig';
import BookResponse from './book-response';
import LoadingBar from 'react-top-loading-bar';
import { strings } from '../../lang';
import { Header } from '../header/header';
import { useSalesChannelState } from '../../store/header-state';

export interface CalendarInfo {
  opportunityId: string;
  onBooking: (isSuccess: boolean, bookedSlot?: Slot, error?: string) => void;
  preBooking?: (oppId: string) => void;
  outerModalClose?: () => void;
  displayHeader?: boolean;
  skipConfirmationAfterBooking?: boolean;
  isInbound?: boolean | undefined;
  sourceSystemHeader?: string | undefined;
}

export const SalesCallCalendar = (calendarInfo: CalendarInfo) => {
  dayjs.extend(isoWeek);
  dayjs.extend(utc);
  const { instance, inProgress, accounts } = useMsal();
  const loadingBarRef = useRef(null);

  const weeks: string[] = [0, 1, 2, 3, 4].map(
    (i) =>
      `${dayjs().year()}-${(dayjs().isoWeek() + i).toString().padStart(2, '0')}`
  );

  const salesChannel = useSalesChannelState((state) => state.salesChannel);
  const [slots, setSlots] = useState<CalendarSlot[]>();
  const [lead, setLead] = useState<Lead>();
  const [week, setWeek] = useState<string>(weeks[0]);
  const [selectedDay] = useState<number>(1);
  const [bookedSlotResult, setBookedSlotResult] = useState<Result<Slot>>();
  const [slotToBook, setSlotToBook] = useState<CalendarSlot>();
  const [isBooking, setIsBooking] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cy = parseInt(week.split(/-/g)[0], 10);
  const cw = parseInt(week.split(/-/g)[1], 10);
  const {
    opportunityId,
    onBooking,
    displayHeader,
    skipConfirmationAfterBooking,
  } = calendarInfo;

  const bookSlot = async (slot: CalendarSlot) => {
    setSlotToBook(slot);
  };

  const cancelBookingSlot = () => {
    setSlotToBook(undefined);
    setBookedSlotResult(undefined);
  };

  const closeConfirmationModal = () => {
    setBookedSlotResult(undefined);
    setSlotToBook(undefined);
    setIsLoading(false);
    if (calendarInfo.isInbound) {
      calendarInfo.outerModalClose?.();
    }
  };

  const confirmBookingSlot = async () => {
    if (bookedSlotResult && bookedSlotResult.isSuccess) {
      window.close();
      return;
    }

    if (!slotToBook || isBooking) {
      return;
    }

    try {
      setIsLoading(true);
      setIsBooking(true);

      const token = await GetAccessToken(
        instance,
        inProgress,
        scopes.salesAppointmentApi
      );

      if (calendarInfo.preBooking) {
        await calendarInfo.preBooking(opportunityId);
      }

      if (!opportunityId) {
        return;
      }
      const bookedSlot = await salesAppointmentsService.bookSlot(
        opportunityId!,
        slotToBook.startDate,
        salesChannel,
        token!.accessToken,
        calendarInfo.sourceSystemHeader,
        calendarInfo.sourceSystemHeader &&
          calendarInfo.sourceSystemHeader === 'inbound-center'
          ? 'reschedule'
          : undefined
      );

      if (bookedSlot.isSuccess && skipConfirmationAfterBooking === true) {
        setSlotToBook(undefined);
        setBookedSlotResult(undefined);
      } else {
        setBookedSlotResult(bookedSlot);
      }

      onBooking(
        bookedSlot.isSuccess,
        bookedSlot.data,
        bookedSlot.errors?.join(', ')
      );
    } catch (e) {
      setSlotToBook(undefined);
      onBooking(false, undefined);
    } finally {
      setIsBooking(false);
      setIsLoading(false);
    }
  };

  /*
  Checks if the booking result has a fatal error.
  If the error is NO_SLOTS_AVAILABLE, then the user should be allowed to pick another slot,
    but if the error is something else, the UI must not allow the user to perform any other action.
  */
  const hasBookingError = (bookingResult: Result<Slot> | undefined) => {
    return (
      bookingResult &&
      !bookingResult.isSuccess &&
      !bookingResult.errors?.find((x) => x.includes('NO_SLOTS_AVAILABLE'))
    );
  };

  useEffect(() => {
    if (inProgress === 'none') {
      // If the booking result threw an error, then reload appointments.
      // Otherwise do nothing, since it does an extra useless call to the backend.
      if (bookedSlotResult && !hasBookingError(bookedSlotResult)) {
        return;
      }

      GetAccessToken(instance, inProgress, scopes.salesAppointmentApi).then(
        async (token) => {
          if (!token) {
            return;
          }
          try {
            const calendarData =
              await salesAppointmentsService.getAvailableSlots(
                week,
                opportunityId!,
                salesChannel,
                token.accessToken,
                calendarInfo.sourceSystemHeader
              );
            //If we try to load the standalone calendar without the Lead Object in it, it means the customer was not found
            if (!calendarData.lead.opportunityId) {
              notification.open({
                key: 'errorNotification',
                message: strings.noOpportunityInCalendarTitle,
                description: (
                  <>
                    <p>{strings.noOpportunityInCalendarMessage}</p>
                  </>
                ),
                placement: 'topRight',
                duration: 0,
                type: 'error',
              });
            }
            setLead(calendarData.lead);
            setSlots(calendarData.slots);
          } catch (e) {
            message.error('Error loading the calendar');
          }
        }
      );
    }
  }, [inProgress, accounts, instance, bookedSlotResult]);

  useEffect(() => {
    if (loadingBarRef.current) {
      if (isLoading) {
        (loadingBarRef.current! as any).staticStart();
      } else {
        (loadingBarRef.current! as any).complete();
      }
    }
  }, [isLoading]);

  // Prevents the user to close or refresh the page while booking.
  useEffect(() => {
    window.onbeforeunload = isBooking ? () => 1 : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [isBooking]);

  // The "null" as string are passed by Salesforce, so we need to validate them.
  if (opportunityId === null || opportunityId === 'null') {
    return (
      <div className="empty-container">
        <AntdResult
          status="warning"
          title="Mandatory input parameters are missing in the querystring"
        />
      </div>
    );
  }

  if (!slots || !lead) {
    return (
      <div className="empty-container">
        <Skeleton loading={true}></Skeleton>
      </div>
    );
  }

  return (
    <>
      <LoadingBar color="#3e7eff" ref={loadingBarRef} shadow={true} />
      {displayHeader && <Header />}
      <div className="cal-page">
        <CalendarParameters
          lead={lead}
          displayUserInfo={displayHeader!}
          isLoading={isLoading}
          onRefresh={async (data) => {
            setIsLoading(true);
            try {
              const token = await GetAccessToken(
                instance,
                inProgress,
                scopes.salesAppointmentApi
              );
              const calendarSlots =
                await salesAppointmentsService.getAvailableSlots(
                  `${data.calendarYear}-${data.calendarWeek}`,
                  opportunityId,
                  salesChannel,
                  token!.accessToken
                );

              setWeek(`${data.calendarYear}-${data.calendarWeek}`);
              setSlots(calendarSlots.slots);
            } finally {
              setIsLoading(false);
            }
          }}
        ></CalendarParameters>

        <CalendarHeader
          calendarWeek={cw}
          calendarYear={cy}
          selectedDay={selectedDay}
        ></CalendarHeader>

        <CalendarBody
          slots={slots}
          onBookSlot={bookSlot}
          isLoading={isLoading}
        />

        <Modal
          title={`Booking`}
          width={600}
          open={slotToBook !== undefined}
          onCancel={cancelBookingSlot}
          onOk={
            bookedSlotResult && bookedSlotResult.isSuccess
              ? closeConfirmationModal
              : confirmBookingSlot
          }
          closable={false}
          okText={
            bookedSlotResult && bookedSlotResult.isSuccess
              ? strings.close
              : 'Ok'
          }
          cancelText={strings.cancel}
          okButtonProps={{
            loading: isBooking,
            disabled: hasBookingError(bookedSlotResult),
          }}
          cancelButtonProps={{
            disabled:
              isBooking || (bookedSlotResult && bookedSlotResult.isSuccess),
          }}
          maskClosable={false}
          keyboard={false}
        >
          <BookResponse
            slotToBook={slotToBook!}
            bookedSlot={bookedSlotResult}
          />
          {isBooking && (
            <p>
              <b>{strings.dontCloseWhileBooking}</b>
            </p>
          )}
        </Modal>
      </div>
    </>
  );
};
