import { datadogRum } from "@datadog/browser-rum";

const {
  REACT_APP_DD_CLIENT_TOKEN,
  REACT_APP_DD_APPLICATION_ID,
  REACT_APP_DD_SITE,
  REACT_APP_DD_SERVICE,
  REACT_APP_DD_ENV,
} = process.env;

if (REACT_APP_DD_APPLICATION_ID && REACT_APP_DD_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: REACT_APP_DD_APPLICATION_ID,
    clientToken: REACT_APP_DD_CLIENT_TOKEN,
    site: REACT_APP_DD_SITE,
    service: REACT_APP_DD_SERVICE ?? "booking-os-calendar",
    env: REACT_APP_DD_ENV ?? "development",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
} else {
  console.warn("[WARN] Missing Application ID or Client Token for Datadog");
}
